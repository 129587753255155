/**=====================
    Loader CSS Start
==========================**/
/**==loader variable==**/
$colors: $primary-color $secondary-color $tertiary-color $info-color;
/**==Calculated variables==**/
$lat: calc((40px - 8px) / 2);
;
$offset: $lat - 8px;
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: $white;
  z-index: 12;
  top: 0;
  .loader {
    width: 100%;
    height: 100%;
    transform: rotate(165deg);
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 50px;
      height: 50px;
      border-radius: calc(8px / 2);
      transform: translate(-50%, -50%);
    }
    &:before {
      animation: before 2s infinite;
    }
    &:after {
      animation: after 2s infinite;
    }
  }
}
@keyframes before {
  0% {
    width: 8px;
    box-shadow:
      $lat (-$offset) nth($colors, 1),
      (-$lat) $offset nth($colors, 3);
  }
  35% {
    width: 40px;
    box-shadow:
      0 (-$offset) nth($colors, 1),
      0 $offset nth($colors, 3);
  }
  70% {
    width: 8px;
    box-shadow:
      (-$lat) (-$offset) nth($colors, 1),
      $lat $offset nth($colors, 3);
  }
  100% {
    box-shadow:
      $lat (-$offset) nth($colors, 1),
      (-$lat) $offset nth($colors, 3);
  }
}
@keyframes after {
  0% {
    height: 8px;
    box-shadow:
      $offset $lat nth($colors, 2),
      (-$offset) (-$lat) nth($colors, 4);
  }
  35% {
    height: 40px;
    box-shadow:
      $offset 0 nth($colors, 2),
      (-$offset) 0 nth($colors, 4);
  }
  70% {
    height: 8px;
    box-shadow:
      $offset (-$lat) nth($colors, 2),
      (-$offset) $lat nth($colors, 4);
  }
  100% {
    box-shadow:
      $offset $lat nth($colors, 2),
      (-$offset) (-$lat) nth($colors, 4);
  }
}
/**=====================
    Loader CSS End
==========================**/