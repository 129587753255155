/**=====================
    Progress css start
==========================**/
.progress {
	[data-theme="dark"] & {
		background-color: var(--body-color);
	}
	&.sm-progress-bar {
		height: 6px;
	}
}
.progress-showcase {
	.progress {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.sm-progress-bar {
	height: 8px;
}
.lg-progress-bar {
	height: 20px;
}
.progress-number {
	[dir="rtl"] & {
		direction: ltr;
	  }
	button {
		width: 2rem;
		height: 2rem;
	}
	.progress-wrapper {
		height: 1px;
		&.progress {
			background-color: var(--recent-dashed-border);
		}
		.progress-bar {
			background-color: var(--theme-default);
		}
	}
}
.progress-btn {
	background-color: var(--light-color);
	border-color: var(--light-color);
	[data-theme="dark"] & {
		color: var(--white);
	}
}
.small-progressbar {
	position: relative;
	overflow: visible;
	&::after {
		position: absolute;
		content: '';
		top: -2px;
		right: -9px;
		background-color: var(--theme-default);
		width: 12px;
		height: 12px;
		border-radius: 100%;
		[dir="rtl"] & {
			left: -9px;
			right: unset;
		}
	}
	.progress-label {
		position: absolute;
		top: -26px;
		right: -6px;
		font-weight: 700;
		[dir="rtl"] & {
			right: unset;
			left: -6px;
		}
	}
	.animate-circle {
		position: absolute;
		top: -11px;
		right: -18px;
		height: 30px;
		width: 30px;
		border: 14px solid $primary-color;
		border-radius: 70px;
		animation: heart 1s ease-in-out;
		animation-iteration-count: infinite;
		[dir="rtl"] & {
			right: unset;
			left: -18px;
		}
	}
}
@keyframes heart {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	25% {
		transform: scale(.1);
		opacity: 1;
	}
	50% {
		transform: scale(.5);
		opacity: .3;
	}
	75% {
		transform: scale(.8);
		opacity: .5;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}
.progress-b-space {
	.progress {
		margin-bottom: 32px;
	}
}
$colors: (primary: $primary-color,
	secondary: $secondary-color,
	tertiary: $tertiary-color,
	warning: $warning-color,
	info: $info-color,
	success: $success-color,
	danger: $danger-color,
	light: $light-color,
	dark: $dark-color);
@each $key,
$val in $colors {
	.progress-striped-#{$key} {
		background-color: rgba($val, 0.1);
		.progress-bar {
			background-image: linear-gradient(120deg, rgba($val, 1) 25%, transparent 25%, transparent 50%, rgba($val, 1) 50%, rgba($val, 1) 75%, transparent 75%, transparent);
			background-size: 16px 30px;
			--bs-progress-bar-bg: rgba($val, 0.1);
			animation: progress-bar-stripes 5s linear infinite;
		}
	}
	.progress-border-#{$key} {
		&.progress-bar {
			position: relative;
			&:after {
				position: absolute;
				content: "";
				top: -2px;
				right: -9px;
				background-color: $val;
				width: 12px;
				height: 12px;
				border-radius: 100%;
			}
		}
	}
	.progress-border-#{$key} {
		&.progress {
			background-color: rgba($val, 0.15);
		}
		.progress-bar {
			position: relative;
			overflow: visible;
			background-color: $val;
			border-radius: 15px 0 0 15px;
			[dir="rtl"] & {
				border-radius: 0px 15px 15px 0px;
			}
			&:after {
				position: absolute;
				content: "";
				top: -4px;
				right: 0;
				background-color: $val;
				width: 1px;
				height: 16px;
				[dir="rtl"] & {
					left: 0;
					right: unset;
				}
			}
		}
	}
}
@keyframes progress-bar-stripes {
	0% {
		background-position: 40px 0;
	}
	100% {
		background-position: 0 0;
	}
}
/**=====================
    Progress css end
==========================**/