/**=====================
    Dashboard 2 css start
==========================**/
.ecommerce-dashboard {
    .sale-progress {
        ul {
            li {
                padding: 13px 20px;
                border-radius: 5px;
                position: relative;
                align-items: center;
                @media (min-width: 1200px) and (max-width: 1559.98px) {
                    gap: 10px !important;
                }
                .flex-shrink-0 {
                    width: 42px;
                    height: 42px;
                    border-radius: 11px;
                    @include flex_common;
                    @media (min-width: 1200px) and (max-width: 1290px) {
                        display: none;
                    }
                }
                .flex-grow-1 {
                    h5 {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                        @include media-breakpoint-down(lg) {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box !important;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            white-space: normal;
                        }
                    }
                    span {
                        color: var(--light-font);
                        background-color: transparent;
                        @media (min-width: 1200px) and (max-width: 1879.98px) {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box !important;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            white-space: normal;
                        }
                    }
                    .progress {
                        height: 7px;
                        margin-top: 6px;
                    }
                }
                .badge {
                    padding: 5px;
                    @media (min-width: 1200px) and (max-width: 1499.98px) {
                        display: none;
                    }
                    @media (max-width: 360px) {
                        display: none;
                    }
                    svg {
                        transform: rotate(-45deg);
                    }
                    [dir="rtl"] & {
                        right: unset;
                        left: 20px;
                    }
                }
                +li {
                    margin-top: 24px;
                    @media (min-width: 1200px) and (max-width: 1216px) {
                        margin-top: 28px;
                    }
                }
            }
        }
    }
    .track-order {
        .card-body {
            background-image: url(../images/dashboard2/delivery.png);
            background-repeat: no-repeat;
            background-position: 96% 86%;
            display: block;
            height: 224px;
            padding: 22px 24px;
            animation: animatedBackground 4s infinite alternate;
            @media (min-width: 1200px) and (max-width: 1699.98px) {
                background-image: none;
            }
            @media (min-width: 768px) and (max-width: 900px) {
                background-image: none;
            }
            @media (max-width: 420px) {
                background-image: none;
                height: 200px;
            }
            @media (max-width: 360px) {
                height: 216px;
            }
            @keyframes animatedBackground {
                0% {
                    background-position: 90% 86%;
                }
                50% {
                    background-position: 94% 86%;
                }
                100% {
                    background-position: 96% 86%;
                }
            }
            p {
                width: 235px;
                line-height: 2;
                margin-bottom: 10px;
            }
            h4 {
                margin-bottom: 8px;
            }
            h5 {
                text-decoration: underline;
                margin-bottom: 20px;
                @media (min-width: 1200px) and (max-width: 1505px) {
                    margin-bottom: 4px;
                }
                @include media-breakpoint-down(lg) {
                    margin-bottom: 12px;
                }
            }
        }
    }
    .manage-order {
        table {
            tr {
                &:first-child {
                    td {
                        padding-top: 0;
                    }
                }
                &:last-child {
                    td {
                        padding-bottom: 0;
                    }
                }
                td {
                    padding: 21px 0;
                    @include media-breakpoint-down(xl) {
                        padding: 9px 0;
                    }
                    @media (min-width: 1200px) and (max-width: 1340px) {
                        padding: 21px 0;
                    }
                    @media (min-width: 1200px) and (max-width: 1269.98px) {
                        padding: 17px 0;
                    }
                    @media (min-width: 1200px) and (max-width: 1216px) {
                        padding: 18px 0;
                    }
                    &:first-child {
                        span {
                            color: var(--text-light-gray);
                        }
                    }
                    &:nth-child(2) {
                        span {
                            color: var(--light-font);
                        }
                    }
                    h6 {
                        &:hover {
                            color: var(--theme-default);
                            transition: 0.5s all;
                        }
                    }
                    span {
                        @media (min-width: 1200px) and (max-width: 1260px) {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box !important;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            white-space: normal;
                        }
                    }
                    img {
                        @media (min-width: 1200px) and (max-width: 1269.98px) {
                            width: 28px !important;
                        }
                    }
                    .d-block {
                        @media (min-width: 1200px) and (max-width: 1499.98px) {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
    .sale-product {
        .col-5 {
            @media (min-width: 1200px) and (max-width: 1699.98px) {
                width: 100% !important;
            }
            @include media-breakpoint-up(md) {
                @include media-breakpoint-down(lg) {
                    width: 100% !important;
                }
            }
            @media (max-width: 420px) {
                width: 100% !important;
            }
        }
        select {
            background-color: var(--light-color);
            border: 0;
            border-radius: 0;
            font-size: 14px;
            color: var(--light-font) !important;
            @media (min-width: 1200px) and (max-width: 1699.98px) {
                display: none !important;
            }
            @include media-breakpoint-up(md) {
                @include media-breakpoint-down(lg) {
                    display: none !important;
                }
            }
            @media (max-width: 420px) {
                display: none !important;
            }
        }
        table {
            margin-top: 22px;
            @media (min-width: 1200px) and (max-width: 1369.98px) {
                margin-top: 10px;
            }
            @include media-breakpoint-down(lg) {
                margin-top: 7px;
            }
            thead {
                tr {
                    th {
                        &:nth-child(3) {
                            @media (min-width: 1200px) and (max-width: 1499.98px) {
                                display: none;
                            }
                            @media (min-width: 768px) and (max-width: 820px) {
                                display: none;
                            }
                            @media (max-width: 420px) {
                                display: none;
                            }
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        border: 0;
                        @media (min-width: 1200px) and (max-width: 1369.98px) {
                            padding: 10px 1px 20px;
                        }
                        &:last-child {
                            min-width: 100px;
                            @media (min-width: 1200px) and (max-width: 1369.98px) {
                                min-width: 47px;
                            }
                            @media (max-width: 360px) {
                                min-width: 80px;
                            }
                        }
                        .progress {
                            height: 8px;
                            overflow: visible;
                        }
                        &:nth-child(3) {
                            @media (min-width: 1200px) and (max-width: 1499.98px) {
                                display: none;
                            }
                            @media (min-width: 768px) and (max-width: 820px) {
                                display: none;
                            }
                            @media (max-width: 420px) {
                                display: none;
                            }
                        }
                    }
                    &:nth-child(n+2) {
                        td {
                            padding-top: 22px;
                            @media (min-width: 1200px) and (max-width: 1369.98px) {
                                padding: 22px 1px 20px;
                            }
                            @media (min-width: 1200px) and (max-width: 1370px) {
                                padding: 15px 1px 20px;
                            }
                            @media (min-width: 1200px) and (max-width: 1300px) {
                                padding: 10px 1px 20px;
                            }
                        }
                    }
                    &:last-child {
                        td {
                            padding-bottom: 20px !important;
                        }
                    }
                }
            }
            tfoot {
                th {
                    border: 0;
                    padding: 9px 12px !important;
                    &:nth-child(n+2) {
                        @media (max-width: 360px) {
                            padding: 9px 0px !important;
                        }
                    }
                    &.f-w-800 {
                        font-weight: 800 !important;
                    }
                }
            }
        }
    }
    .selling-table {
        .datatable-top {
            @media (min-width: 1200px) and (max-width: 1499.98px) {
                display: none;
            }
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
        table {
            thead {
                tr {
                    th {
                        padding: 12px 4px !important;
                        &:first-child {
                            @media (min-width: 1200px) and (max-width: 1405px) {
                                display: none;
                            }
                        }
                        &:nth-child(3) {
                            @media (min-width: 1200px) and (max-width: 1432px) {
                                display: none;
                            }
                        }
                        &:nth-child(4) {
                            @media (min-width: 1200px) and (max-width: 1880px) {
                                display: none;
                            }
                        }
                        &:nth-child(5) {
                            @media (min-width: 1200px) and (max-width: 1550px) {
                                display: none;
                            }
                        }
                    }
                }
            }
            tbody {
                tr {
                    &:last-child {
                        td {
                            padding-bottom: 0 !important;
                        }
                    }
                    td {
                        padding: 12px 3px !important;
                        @media (min-width: 1200px) and (max-width: 1499.98px) {
                            padding: 10px 3px !important;
                        }
                        @media (min-width: 1200px) and (max-width: 1339.98px) {
                            padding: 13px 3px !important;
                        }
                        @media (min-width: 1200px) and (max-width: 1260px) {
                            padding: 8px 3px !important;
                        }
                        @media (min-width: 1200px) and (max-width: 1269.98px) {
                            padding: 5px 3px !important;
                        }
                        @media (min-width: 1200px) and (max-width: 1216px) {
                            padding: 6px 3px !important;
                        }
                        .flex-grow-1 {
                            h6 {
                                &:hover {
                                    color: var(--theme-default);
                                }
                                @media (min-width: 1200px) and (max-width: 1230px) {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    display: -webkit-box !important;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    white-space: normal;
                                }
                            }
                        }
                        &:first-child {
                            padding-right: 0 !important;
                            @media (min-width: 1200px) and (max-width: 1405px) {
                                display: none;
                            }
                        }
                        &:nth-child(2) {
                            .flex-shrink-0 {
                                height: 38px;
                                width: 38px;
                                border-radius: 10px;
                                background: var(--light-color);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            @include media-breakpoint-down(xl) {
                                min-width: 180px;
                            }
                        }
                        &:nth-child(3) {
                            @media (min-width: 1200px) and (max-width: 1432px) {
                                display: none;
                            }
                            @include media-breakpoint-down(xl) {
                                min-width: 80px;
                            }
                        }
                        &:nth-child(4) {
                            @media (min-width: 1200px) and (max-width: 1880px) {
                                display: none;
                            }
                        }
                        &:nth-child(5) {
                            @media (min-width: 1200px) and (max-width: 1550px) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    [class*="circle-dot-"] {
        width: 20px;
        height: 20px;
        span {
            width: 10px;
            height: 10px;
        }
    }
    .category {
        .col-xl-6 {
            @media (min-width: 1200px) and (max-width: 1499.98px) {
                width: 100% !important;
            }
            @include media-breakpoint-up(md) {
                @include media-breakpoint-down(lg) {
                    width: 100% !important;
                }
            }
            @media (max-width: 460px) {
                width: 100% !important;
            }
        }
        .apexcharts-datalabel-label {
            transform: translateY(25px) !important;
        }
        .apexcharts-datalabel-value {
            transform: translateY(-35px) !important;
        }
        .apexcharts-canvas {
            .apexcharts-radialbar-hollow {
                width: 108px;
                height: 108px;
                fill: #FDFDFF;
                filter: drop-shadow(0px 24.580244064331055px 11.172839164733887px rgba(62, 58, 100, 0.10));
                [data-theme="dark"] & {
                    fill: var(--body-color);
                }
            }
        }
        ul {
            @media (min-width: 1200px) and (max-width: 1499.98px) {
                display: none !important;
            }
            @include media-breakpoint-up(md) {
                @include media-breakpoint-down(lg) {
                    display: none !important;
                }
            }
            @media (max-width: 460px) {
                display: none !important;
            }
            li {
                padding: 16px 14px;
                gap: 10px;
                h6 {
                    @media (min-width: 1200px) and (max-width: 1879.98px) {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                    }
                    @include media-breakpoint-down(xl) {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                    }
                }
                span {
                    @media (min-width: 1200px) and (max-width: 1879.98px) {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                    }
                }
                +li {
                    margin-top: 26px;
                    @media (min-width: 1200px) and (max-width: 1860px) {
                        margin-top: 34px;
                    }
                    @media (min-width: 992px) and (max-width: 1199.98px) {
                        margin-top: 35px;
                    }
                }
            }
        }
        .category-chart {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .discount-product {
        padding: 18px 24px;
        @media (min-width: 1200px) and (max-width: 1444.98px) {
            padding: 18px 10px;
        }
        .discount-title {
            @media (min-width: 1200px) and (max-width: 1400px) {
                font-size: 15px;
            }
            @include media-breakpoint-up(md) {
                @include media-breakpoint-down(lg) {
                    font-size: 18px;
                }
            }
            @media (max-width: 360px) {
                font-size: 14px;
            }
        }
        .discount-content {
            @media (min-width: 1200px) and (max-width: 1300px) {
                font-size: 17px;
            }
            @include media-breakpoint-up(md) {
                @include media-breakpoint-down(lg) {
                    font-size: 14px;
                }
            }
            @media (max-width: 360px) {
                font-size: 14px;
            }
        }
        hr {
            opacity: 0.4;
            margin: 18px 0;
        }
        .row {
            div[class*="col-"] {
                +div[class*="col-"] {
                    padding-left: 22px;
                    border-left: var(--light-border);
                }
                >h4 {
                    margin-bottom: 24px;
                }
            }
        }
        .main-box {
            width: 58px;
            height: 55px;
            border-radius: 10px;
            @include flex_common;
            @media (min-width: 1200px) and (max-width: 1339.98px) {
                width: 44px;
                height: 44px;
            }
            @media (max-width: 420px) {
                width: 46px;
                height: 43px;
            }
            &:hover {
                svg {
                    fill: var(--white);
                }
                &.border-primary {
                    background-color: var(--theme-default);
                }
                &.border-secondary {
                    background-color: var(--theme-secondary);
                }
            }
        }
        .d-flex {
            h4 {
                width: 120px;
            }
            .bg-light-primary {
                @media (min-width: 1200px) and (max-width: 1527.98px) {
                    display: none;
                }
                @media (max-width: 420px) {
                    display: none;
                }
            }
            .bg-light-secondary {
                @media (min-width: 1200px) and (max-width: 1527.98px) {
                    display: none;
                }
                @media (max-width: 420px) {
                    display: none;
                }
            }
        }
    }
    .seller-table {
        table {
            thead {
                th {
                    &:first-child {
                        @media (min-width: 1200px) and (max-width: 1330px) {
                            max-width: 50px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    &:nth-child(3) {
                        @media (min-width: 1200px) and (max-width: 1684.98px) {
                            display: none;
                        }
                    }
                    &:nth-child(4) {
                        @media (min-width: 1200px) and (max-width: 1879.98px) {
                            display: none;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding: 20px 8px;
                        @media (min-width: 1200px) and (max-width: 1400px) {
                            padding: 18px 8px;
                        }
                        @media (min-width: 1200px) and (max-width: 1300px) {
                            padding: 17px 8px;
                        }
                        @media (min-width: 1200px) and (max-width: 1216px) {
                            padding: 18px 8px;
                        }
                        img {
                            width: 38px;
                            height: 38px;
                        }
                        span {
                            color: var(--text-light-gray);
                        }
                        h6 {
                            &:hover {
                                color: var(--theme-default);
                                transition: 0.5s all;
                            }
                        }
                        .btn {
                            padding: 6px 5px;
                        }
                        &:first-child {
                            @media (max-width: 540px) {
                                min-width: 148px;
                            }
                            span {
                                @media (min-width: 1200px) and (max-width: 1394.98px) {
                                    display: none;
                                }
                            }
                            h6 {
                                @media (min-width: 1200px) and (max-width: 1345px) {
                                    display: none;
                                }
                            }
                        }
                        &:nth-child(3) {
                            @media (min-width: 1200px) and (max-width: 1684.98px) {
                                display: none;
                            }
                            @media (max-width: 540px) {
                                min-width: 100px;
                            }
                        }
                        &:nth-child(4) {
                            @media (min-width: 1200px) and (max-width: 1879.98px) {
                                display: none;
                            }
                        }
                        &:last-child {
                            @media (max-width: 545px) {
                                min-width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
    .progress-items {
        .stroke-danger {
            stroke: $danger-color !important;
        }
        .stroke-success {
            stroke: $success-color !important;
        }
        .flex-shrink-0 {
            width: 49px;
            height: 45px;
            @include flex_common;
            &.bg-light-primary {
                background: linear-gradient(180deg, rgba($primary-color, 0.19) 7.29%, rgba($primary-color, 0) 100%);
            }
            &.bg-light-secondary {
                background: linear-gradient(180deg, rgba($secondary-color, 0.19) 7.29%, rgba($secondary-color, 0) 100%);
            }
            &.bg-light-tertiary {
                background: linear-gradient(180deg, rgba($tertiary-color, 0.19) 7.29%, rgba($tertiary-color, 0) 100%);
            }
            @media (min-width: 1200px) and (max-width: 1339.98px) {
                width: 40px;
                height: 35px;
            }
            @media (min-width: 1200px) and (max-width: 1260px) {
                width: 25px;
                height: 27px;
            }
            @include media-breakpoint-up(md) {
                @include media-breakpoint-down(lg) {
                    width: 39px;
                    height: 37px;
                }
            }
            @include media-breakpoint-down(sm) {
                width: 39px;
                height: 39px;
            }
        }
        .progress-card {
            display: flex;
            align-items: end;
            justify-content: space-between;
            gap: 6px;
            .order-chart {
                margin: -47px -10px -40px -20px;
            }
            p {
                @media (min-width: 1200px) and (max-width: 1499.98px) {
                    display: none;
                }
                @media (min-width: 768px) and (max-width: 975px) {
                    display: none;
                }
            }
            @media (min-width: 1200px) and (max-width: 1499.98px) {
                width: 100% !important;
                display: block !important;
            }
            @media (min-width: 768px) and (max-width: 975px) {
                width: 100% !important;
                display: block !important;
            }
        }
        .badge {
            @media (min-width: 1200px) and (max-width: 1570px) {
                display: none !important;
            }
            @include media-breakpoint-up(md) {
                @include media-breakpoint-down(lg) {
                    display: none !important;
                }
            }
        }
        h3 {
            @media (min-width: 1200px) and (max-width: 1339.98px) {
                font-size: 20px !important;
                margin-top: 6px !important;
            }
            @media (min-width: 1200px) and (max-width: 1269.98px) {
                font-size: 18px !important;
            }
            @include media-breakpoint-down(lg) {
                font-size: 21px !important;
                margin-top: 6px !important;
            }
            [data-theme="dark"] & {
                color: var(--light-font);
            }
        }
    }
    .product-offer {
        .dropdown-menu {
            z-index: 9;
        }
        .card-body {
            @media (min-width: 1200px) and (max-width: 1499.98px) {
                padding-top: 0 !important;
            }
        }
        .swiper {
            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
                .item {
                    .product-gif {
                        width: 58px;
                        height: 58px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        @media (min-width: 1200px) and (max-width: 1499.98px) {
                            width: 49px;
                            height: 49px;
                        }
                        @media (min-width: 1200px) and (max-width: 1399.98px) {
                            width: 32px;
                            height: 32px;
                        }
                        @include media-breakpoint-down(xl) {
                            width: 70px;
                            height: 70px;
                        }
                        @media (max-width: 420px) {
                            width: 50px;
                            height: 50px;
                        }
                    }
                    .product-content {
                        margin-top: 20px;
                        @media (min-width: 1200px) and (max-width: 1379.98px) {
                            margin-top: 1px;
                        }
                        h4 {
                            a {
                                [data-theme="dark"] & {
                                    color: var(--light-font);
                                }
                            }
                        }
                    }
                }
            }
            .swiper-wrapper {
                @media (min-width: 1200px) and (max-width: 1499.98px) {
                    padding-bottom: 20px !important;
                }
            }
            .swiper-pagination {
                display: inline-grid;
                position: absolute;
                top: 35%;
                right: 0;
                left: unset;
                margin-bottom: -10px;
                bottom: 110px !important;
                width: unset !important;
                margin-top: 25px;
                @media (min-width: 1200px) and (max-width: 1499.98px) {
                    bottom: 10px !important;
                    display: unset !important;
                    left: 50% !important;
                    top: unset !important;
                    transform: translateX(-50%) !important;
                }
                .swiper-pagination-bullet {
                    width: 7px;
                    height: 7px;
                    background-color: var(--theme-default);
                    &.swiper-pagination-bullet-active {
                        height: 25px;
                        border-radius: 10px;
                        @media (min-width: 1200px) and (max-width: 1499.98px) {
                            width: 25px;
                            border-radius: 10px;
                            height: 7px;
                        }
                    }
                }
            }
        }
    }
    .sales-summary {
        .card-header {
            @media (min-width: 1200px) and (max-width: 1379.98px) {
                padding-bottom: 0;
            }
            @include media-breakpoint-down(xl) {
                padding-bottom: 0;
            }
        }
        .card-body {
            @media (min-width: 1200px) and (max-width: 1379.98px) {
                padding-top: 0;
            }
            @include media-breakpoint-down(xl) {
                padding-top: 0;
            }
        }
    }
}
.overview-wrapper {
    position: relative;
    z-index: 1;
}
.back-bar-container {
    position: absolute;
    width: calc(100% - 64px);
    bottom: 13px;
    margin: 0 auto !important;
    left: 45px;
}
/**=====================
    Dashboard 2 css start
==========================**/